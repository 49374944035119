import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Masonry from "react-masonry-component"
import SEO from "../components/seo"
import { OutboundLink } from "gatsby-plugin-google-analytics"

export const query = graphql`
  query ArticleQuery($id: String!) {
    strapiAlbum(strapiId: { eq: $id }) {
      Title
      Pictures {
        id
        url
        childImageSharp {
          fluid(maxWidth: 800, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Description
      Country
      FlickrLink
      updatedAt
    }
  }
`

const Album = ({ data }) => {
  const album = data.strapiAlbum
  const updatedDate = new Date(album.updatedAt)
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const year = updatedDate.getFullYear()
  const month = months[updatedDate.getMonth()]
  const day = updatedDate.getDate()

  return (
    <Layout>
      <SEO title={album.Title} />
      <div className="flex flex-wrap px-6 py-4">
        <div className="w-full sm:w-full md:w-full lg:w-full xl:w-full pl-4">
          <Masonry>
            <div className="w-full sm:w-full md:w-full lg:w-4/12 xl:w-4/12 rounded shadow-lg description-card lg:h-screen xl:h-screen overflow-auto bg-gray-200 mb-4">
              <div className="px-6 py-4">
                <div className="mb-8">
                  <p className="text-sm text-gray-600 flex items-center">
                    Updated: {month} {day}/{year}
                  </p>
                  <div className="text-gray-900 font-bold text-xl mb-2">
                    {album.Title}
                  </div>
                  <p className="text-gray-700 text-base whitespace-pre-wrap">
                    {album.Description}
                  </p>
                </div>
              </div>
            </div>
            {album.Pictures.map(node => (
              <div className="w-full md:w-full md:w-4/12 lg:w-4/12 xl:w-4/12 px-2 pb-4">
                <div className="rounded overflow-hidden shadow-lg">
                  <Img fluid={node.childImageSharp.fluid} />
                </div>
              </div>
            ))}
          </Masonry>
          <div className="flex flex-col items-center pt-10">
            <OutboundLink href={album.FlickrLink} target="_blank">
              <button className="hover px-6 py-2 transition ease-in duration-200 uppercase rounded-full bg-blue-500 hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none">
                See the full album on Flickr
              </button>
            </OutboundLink>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Album
